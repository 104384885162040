.card-group
{
    display: flex;
    flex-direction: column;
    margin: 10px;
   
}
.card-container
{
    display: flex;
    flex-direction: column;
}
.card{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
}
h2{
   margin-right: 8rem;
}