*{
    padding: 0%;
    margin: 0%;
}
.board1
{
   
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
     justify-content: space-evenly;
    align-items: flex-start;
    text-align: center;
    margin-top: 6rem;
    width: 99%;
 
}
.user-icon{
width: 250px;
height: 700px;
}

