.nav{
    background-color: rgb(231, 231, 231);
     display: flex;
    
     align-items: center;
     padding: 0,30px;
     box-shadow: 0 5px 15px 0 rgba(175, 168, 168, 0.25);
     border-radius: 13px;
     width: 100%;
     height: 80px;
     position: absolute;
     top: 10px;
     left: 50%;
     transform: translate(-50%);
  
}
h2{
    margin-left: 30%; /* Remove default margin */
    text-align: center; /* Center align the text */
}
.nav-link
{
   margin-left: 10rem;
}
@media screen and (max-width:550px )
{
    .nav-link
    {
        margin-left: 2rem;
    }
   .nav{
    width: 85%;
    margin: auto;
   }
}
    

